import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import Spiner from "../../utilities/Spiner";
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import { getSpecificUser } from "../../redux/actions/usersAction";
import ShopNavbar from "../../components/shop/ShopNavbar";
import ShopFooter from "../../components/shop/ShopFooter";

const UserDetails = () => {
  const [specificUserDetails, setSpecificUserDetails] = useState([]);
  const [userDetailsLoading, setUserDetailsLoading] = useState([]);
  const [role, setRole] = useState("");

  const { id } = useParams();

  const dispatch = useDispatch();
  const specificUserData = useSelector(
    (state) => state.usersReducer.specificUser
  );
  const loggedUserData = useSelector((state) => state.authReducer.getMe);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      dispatch(getSpecificUser(id));
    }
  }, [id]);

  useEffect(() => {
    if (specificUserData) {
      if (specificUserData.status === 200) {
        if (specificUserData.data) {
          setSpecificUserDetails(specificUserData.data.data);
          setUserDetailsLoading(false);
        }
      }
    }
  }, [specificUserData]);

  useEffect(() => {
    dispatch(getLoggedUser());
  }, []);

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "admin"){
            navigate('/shop')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/shop')
      }
    }
  },[loggedUserData])

  return (
    <div className="order-details-page">
      <ShopNavbar/>
      <div className="container">
        {userDetailsLoading ? (
          <Spiner />
        ) : specificUserDetails ? (
          <div className="order-wraper">
            <h2>User Info Details</h2>
            <div className="order-details-container">
              <div className="order-details">
                <div className="user-info">
                  <h3>User Info</h3>
                  <ul>
                    <li>
                      Username:
                      <p>{specificUserDetails.name}</p>
                    </li>
                    <li>
                      Email:
                      <p>{specificUserDetails.email}</p>
                    </li>
                    <li>
                      Phone Number:
                      <p>{specificUserDetails.phone}</p>
                    </li>
                    <li>
                      Registration Date:
                      <p>{specificUserDetails.createdAt}</p>
                    </li>
                    <li>
                      Role:
                      <p>{specificUserDetails.role}</p>
                    </li>
                  </ul>
                </div>
                <div className="shipping-info">
                  <h3>User Addresses</h3>
                  {specificUserDetails.addresses.length > 0 ? (
                    specificUserDetails.addresses.map((address, index) => (
                      <ul className="address-list" key={index}>
                        <li>
                          City:
                          <p>{address.city}</p>
                        </li>
                        <li>
                          Address Details:
                          <p>{address.details}</p>
                        </li>
                        <li>
                          Phone Number:
                          <p>{address.phone}</p>
                        </li>
                        <li>
                          Postal Code:
                          <p>{address.postalCode}</p>
                        </li>
                      </ul>
                    ))
                  ) : (
                    <p className="no-item">No Addresses For This User</p>
                  )}
                </div>
              </div>
              <div className="order-items"></div>
            </div>
          </div>
        ) : null}
      </div>
      <ShopFooter/>
    </div>
  );
};

export default UserDetails;
