import React from 'react'
import ShopNavbar from '../../components/shop/ShopNavbar'
import LatestBlog from '../../components/Blog/LatestBlog'
import Footer from '../../components/Blog/Footer'
import { Helmet } from "react-helmet";

const Blogs = () => {
  return (
    <div className='page-container'>
      <Helmet>
            <meta charSet="utf-8" />
            <title>Adam Superfoods | المدونة</title>
            <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
      <ShopNavbar/>
      <LatestBlog/>
      <Footer/>
    </div>
  )
}

export default Blogs