import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import Part1 from "../../images/imgs/basma-logo.png";
import Part2 from "../../images/imgs/snabel-logo.png";
import Part3 from "../../images/imgs/melina-logo.png";
import Part4 from "../../images/imgs/emilia-logo.png";
import Part5 from "../../images/imgs/omara-logo.png";

import { Autoplay } from "swiper/modules";

const Testimonials = () => {
  const imagesList = [Part1, Part2, Part3, Part4, Part5];
  return (
    <section className="testimonials">
      <div className="container">
        <div className="testimonials-container">
          <div className="title">
            <h5>Success stories</h5>
            <h3>Success partners</h3>
          </div>
          <div className="testimonials-wraper">
            <div className="testimonial">
              <div className="testimonial-details">
                <p>
                  Adam for Foodstuffs has been privileged to contribute to the
                  building and strengthening of many global brands. Through
                  mutual cooperation, these brands have gained the favor of
                  their audiences, achieved broad and sustainable successes, and
                  have been the fastest growing.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="parteners">
        <Swiper
          className="mySwiper"
          slidesPerView={5}
          spaceBetween={20}
          breakpoints={{
            350: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
        >
          {imagesList.map((img, index) => (
            <SwiperSlide key={index}>
              <img loading="lazy" src={img} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <p className="last-p">
      The companies that own these brands produce a wide range of food products of the highest quality. However, when it comes to molasses or tahini, they must be aware that despite their high value, they require special expertise and skill to produce them in a way that combines taste, nutritional value, and high quality. This is why distinguishing their brand with an exceptional and unique product required joint cooperation for Adam to do the production and packaging, and they distributed it within their country under their own brand. This approach enabled them to offer unique products that were exclusive to Adam until they were able to gain the trust of consumers interested in high-quality products, and our products became the spearhead of their product portfolio all along the line.
      </p>
    </section>
  );
};

export default Testimonials;
