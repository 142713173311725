import React from 'react'
import Logo from '../images/imgs/logo-light.png'
import Phone from '../images/svg/phone.svg'
import Mail from '../images/svg/mail.svg'
import Location from '../images/svg/location.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='footer ar'>
        <div className='container'>
            <div className='footer-container'>
                <div className='info-footer'>
                    <div className='logo-footer'>
                        <img src={Logo} alt='logo-footer'/>
                    </div>
                    <div className='info-footer-wraper'>
                        <p>
                        مهمتنا هي تثقيف الأجيال الحالية والمستقبلية حول أساسيات الصحة والعافية.
                        </p>
                    </div>
                    <div className='copyright'>
                        <p>© 2024 ADAM, Developed by <a href='https://www.facebook.com/omar.zezo.5268/' target='_blank'>Omar Abd El-aziz</a></p>
                    </div>
                </div>
                <div className='links-footer'>
                    <h6>أهم الروابط</h6>
                    <div className='links-footer-wraper'>
                        <ul>
                            <li>
                            <Link to="/">الرئيسية</Link>
                            </li>
                            <li>
                            <Link to="/about">من نحن</Link>
                            </li>
                            <li>
                            <Link to="/blog">المدونة</Link>
                            </li>
                            <li>
                            <Link to="/contact">تواصل معنا</Link>
                            </li>
                            <li>
                            <Link to="/shop">المتجر</Link>
                            </li>
                        </ul>

                        <ul>
                            <li>
                            <Link to="">دبس أدم</Link>
                            </li>
                            <li>
                            <Link to="">طحينة أدم</Link>
                            </li>
                            <li>
                            <Link to="">لينكد ان</Link>
                            </li>
                            <li>
                            <Link to="">فيسبوك</Link>
                            </li>
                            <li>
                            <Link to="">إنستجرام</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='contacts-footer'>
                    <h6>كن على تواصل</h6>
                    <ul className='contacts-footer-wraper'>
                        <li>
                            <Link to="">
                                <img src={Phone} alt=''/>
                                +201001103654
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <img src={Mail} alt=''/>
                                info@adamfoodseg.com
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <img src={Location} alt=''/>
                                Egypt
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer