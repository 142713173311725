import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from "../../components/admin/Sidebar";
import { Link } from "react-router-dom";
import { ChangeDeliverStatus, ChangePaymentStatus, getAllOrders } from "../../redux/actions/orderAction";
import Spiner from "../../utilities/Spiner";
import Check from '../../images/svg/check-mark.svg'
import Cart from '../../images/svg/cart.svg'
import { useNavigate } from "react-router-dom";
import { getLoggedUser } from "../../redux/actions/authAction";
import ShopNavbar from "../../components/shop/ShopNavbar";
import ShopFooter from "../../components/shop/ShopFooter";


const ManageOrders = () => {
  const [ordersList, setOrdersList] = useState([]);
  const [ordersLoading, setOrdersLoading] = useState(true)
  const [allOrders, setAllOrders] = useState([]);

  const ordersData = useSelector((state) => state.orderReducer.orders);
  const loggedUserData = useSelector(state=>state.authReducer.getMe)
  const dispatch = useDispatch();

  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getLoggedUser())
    dispatch(getAllOrders());
  }, []);

  useEffect(() => {
    if (ordersData) {
      if (ordersData.status === 200) {
        if (ordersData.data) {
          setOrdersList(ordersData.data.data);
          setAllOrders(ordersData.data.data)
          setOrdersLoading(false)
        }
      }
    }
  }, [ordersData]);

  //handel payment

  const paymentData = useSelector(state=> state.orderReducer.paymentStatus)

  useEffect(()=>{
    if(paymentData){
      if(paymentData.status === 200){
        window.location.reload()
      }
    }
  },[paymentData])

  const handelPayment = (id)=>{
    dispatch(ChangePaymentStatus(id))
  }

  //handel deliver

  const deliverData = useSelector(state=> state.orderReducer.deliverStatus)

  useEffect(()=>{
    if(deliverData){
      if(deliverData.status === 200){
        window.location.reload()
      }
    }
  },[deliverData])

  const handelDeliver = (id)=>{
    dispatch(ChangeDeliverStatus(id))
  }

  useEffect(()=>{
    if(loggedUserData){
      if(loggedUserData.status === 200){
        if(loggedUserData.data){
          if(loggedUserData.data.data.role !== "admin"){
            navigate('/shop')
          }
        }
      }else if(loggedUserData.status === 401){
        navigate('/shop')
      }
    }
  },[loggedUserData])

  const onSearchUser = (e)=>{
    if(e.target.value !== ""){
      const filtredOrdersList = ordersList.filter((order)=> order.user.email.includes(e.target.value))
      setOrdersList(filtredOrdersList)
    }else{
      setOrdersList(allOrders)
    }
  }

  return (
    <div className="admin-page">
      <ShopNavbar/>
      <div className="container">
        <div className="admin-page-wraper">
          <Sidebar />
          <section className="dashboard-container">
            <h3>Manage Orders</h3>
            <div className="search-form">
              <input type="text" placeholder="Entar Email To Search" onChange={(e)=>onSearchUser(e)}/>
            </div>
            <div className="add-wraper">
              {ordersLoading ? (
                <Spiner />
              ) : ordersList.length > 0 ? (
                ordersList.map((order, index) => (
                  <div className="coupon-card" key={index}>
                    <Link to={`/admin/order-details/${order._id}`}>
                      <span className="order-number">#{order.id}</span>
                      <div className="coupon-img">
                        <img src={Cart} alt="cart"/>
                      </div>
                      <ul className="coupon-details">
                        <li>
                          Username:
                          <p>{order.user.name}</p>
                        </li>
                        <li>
                          Email:
                          <p>{order.user.email}</p>
                        </li>
                        <li>
                          City:
                          <p>{order.shippingAddress.city}</p>
                        </li>
                        <li>
                          Address:
                          <p>{order.shippingAddress.details}</p>
                        </li>
                        <li>
                          Phone Number:
                          <p>{order.shippingAddress.phone}</p>
                        </li>
                        <li>
                          Order's Date:
                          <p>{order.createdAt}</p>
                        </li>
                      </ul>
                    </Link>
                    <div className="order-status">
                          <div className="order-deliver">
                            {
                              order.isDelivered ? (
                                <img src={Check} alt="ckeck"/>
                              ):null
                            }
                            <select onChange={()=>{handelDeliver(order._id)}} >
                              {
                                order.isDelivered ? (
                                  <>
                                  <option value="Delivered">Delivered</option>
                                  <option value="Not Delivered">Not Delivered</option>
                                  </>
                                ):(
                                  <>
                                  <option value="Not Delivered">Not Delivered</option>
                                  <option value="Delivered">Delivered</option>
                                  </>
                                )
                              }                           
                            </select>
                          </div>
                          <div className="order-paid">
                            {
                              order.isPaid ? (
                                <img src={Check} alt="ckeck"/>
                              ):null
                            }                           
                            <select onChange={()=>handelPayment(order._id)}>
                            {
                                order.isPaid ? (
                                  <>
                                  <option value="Paid">Paid</option>
                                  <option value="Not Paid">Not Paid</option>
                                  </>
                                ):(
                                  <>
                                  <option value="Not Paid">Not Paid</option>
                                  <option value="Paid">Paid</option>
                                  </>
                                )
                              }          
                            </select>
                          </div>
                    </div>
                  </div>
                ))
              ) : (
                <p className="no-items">No Orders Yet</p>
              )}
            </div>
          </section>
        </div>
      </div>
      <ShopFooter/>
    </div>
  );
};

export default ManageOrders;
