import React, { useState } from "react";
import { Link } from "react-router-dom";
import SideImg1 from "../../../images/imgs/design-4.webp";
import Design5 from "../../../images/imgs/design-5.webp";
import Design6 from "../../../images/imgs/design-6.webp";
import Design7 from "../../../images/imgs/design-7.webp";
import Design8 from "../../../images/imgs/design-8.webp";
import Design9 from "../../../images/imgs/design-9.webp";

const InfoAr = () => {
  const [linkIndek, setLinkIndex] = useState("1");
  return (
    <section className="info">
      <div className="container">
        <div className="info-wraper">
          <div className="links ar">
            <ul className="ar">
              <li
                id="1"
                className={linkIndek === "1" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>01</small>
                من نحن
              </li>
              <li
                id="2"
                className={linkIndek === "2" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>02</small>
                رؤيتنا
              </li>
              <li
                id="3"
                className={linkIndek === "3" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>03</small>
                رسالتنا
              </li>
              <li
                id="4"
                className={linkIndek === "4" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>04</small>
                نشأتنا
              </li>
              <li
                id="5"
                className={linkIndek === "5" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>05</small>
                المعايير المتبعة
              </li>
              <li
                id="6"
                className={linkIndek === "6" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>06</small>
                منتجاتنا
              </li>
              <li
                id="7"
                className={linkIndek === "7" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>07</small>
                الدبس والطحينة
              </li>
              <li
                id="8"
                className={linkIndek === "8" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>08</small>
                منهجيتنا الفريدة
              </li>
              <li
                id="9"
                className={linkIndek === "9" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>09</small>
                أدم حول العالم
              </li>
            </ul>
          </div>

          <div className="details-container">
            <div className="details ar">
              <h3
                id="1"
                className={linkIndek === "1" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>01</small>
                فلسفاتنا
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "1" ? "flex" : "none" }}
              >
                <div className="img">
                  <img src={SideImg1} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>فلسفاتنا</h5>
                  <h2>
                    <span>01</span>
                    شركة أدم
                  </h2>
                  <p>
                    نؤمن بأن الصحة والقوة واللياقة البدنية أهم ركائز السعادة
                    بحياتنا وهدف رئيسي للعديد، وانه لا يمكن ان يكون السبيل
                    الوحيد لتحقيقهما عبر الحميات الغذائية القاسية والتي لا تنجح
                    سوي مع القلة لفترة محدودة قبل ان تتدهور صحتهم، والامتناع عن
                    متعة تناول ما نحبه. لذا تبنت آدم فلسفة فريدة تركز على الجمع
                    بين التغذية الكاملة والشفاء العميق دون المساس بالمتعة
                  </p>
                  <p>
                  لذا قمنا بتطوير ألعديد من الأغذية فائقة القيمة الغذائية وانتجناها بصورة مثالية لتصبح بديل مثالي للعديد من المواد الضارة المتكرر استهلاكها يوميا وبصورة نقية وطبيعية 100%، بالإضافة الي سهولة توظيفها حسب الهدف وتعدد الاستخدام. وذلك من أجل توفير مواد فائقة القيمة نستمتع بتناولها وتحقق أهدافنا الصحية بنفس الوقت. فالأرض ما زالت تثمر بالأطعمة التي لم يتم التلاعب بها وحافظت على حالتها الخام الطبيعية والتي تحتوي عل تركيزات عالية بالعديد من العناصر الحيوية ليظهر مدي احتياجنا لها بأيامنا تلك لتعوضنا عما سلب منّا، وما علينا سوي نتعامل مع هذه المكونات بكل بساطة ومهارة.
                  </p>
                  <Link to="/ar/about" className="read-more">
                    قراءة المزيد
                  </Link>
                </div>
              </div>
            </div>

            <div className="details ar">
              <h3
                id="2"
                className={linkIndek === "2" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>01</small>
                رؤيتنا
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "2" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design5} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>رؤيتنا</h5>
                  <h2>
                    <span>02</span>
                    رؤيتنا
                  </h2>
                  <p>
                    نسعى إلى عالم يتمتع فيه الجميع بحضارة اليوم وصحة الأجداد،
                    وأن نرتقي بجنسنا إلى أفضل نسخة منا
                  </p>
                  <Link to="/ar/about" className="read-more">
                    قراءة المزيد
                  </Link>
                </div>
              </div>
            </div>

            <div className="details ar">
              <h3
                id="3"
                className={linkIndek === "3" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>03</small>
                رسالتنا
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "3" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design5} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>رسالتنا</h5>
                  <h2>
                    <span>03</span>
                    رسالتنا
                  </h2>
                  <p>
                    تتمثل رسالتنا بتوعية الأجيال الحالية والقادمة بمبادئ
                    وأساسيات الصحة وتحذيرهم من مسببات الامراض مع توفير حلول
                    فعالة بأعلى جودة لجذبهم نحو تحقيق أهدافهم وعيش حياة أكثر
                    سعادة وصحة وتعويضهم بالقيم الغذائية التي يفتقرون اليها بسبب
                    كثرة ما حولهم من خيارات سلبية وتعليمهم كيفية توظيف تلك
                    الحلول والتنوع باستخدامها
                  </p>
                  <Link to="/ar/about" className="read-more">
                    قراءة المزيد
                  </Link>
                </div>
              </div>
            </div>

            <div className="details ar">
              <h3
                id="4"
                className={linkIndek === "4" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>04</small>
                نشأتنا
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "4" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={SideImg1} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>نشأتنا</h5>
                  <h2>
                    <span>04</span>
                    نشأتنا
                  </h2>
                  <p>
                    تأسست شركة آدم للمواد الغذائية منذ عام 1995 حاملة على عاتقها
                    مهمة جعل الصحة والعافية في متناول الجميع، هادفة إلى جعل هذا
                    الاختيار متاحًا دون التسبب بعقدة تجاه فكرة التحول الى اسلوب
                    حياة صحي.  ولإيماننا بأن أي تغيير ناجح في حياة الإنسان يبدأ
                    باستبدال قليل من العادات السلبية المتكررة بعادات أفضل. وهذا
                    مبدأ أصيل تضعه الشركة في الاعتبار عندما يتعلق الأمر بتصحيح
                    وتغيير حياة أفراد المجتمع نحو الأفضل دون التسبب في تعطيل
                    أنماط حياتهم. لذلك وضعت آدم بعض المعايير لاختيار المنتجات
                    التي تقدمها لضمان مساهمتها في تحسين الصحة العامة.
                  </p>
                  <Link to="/ar/about" className="read-more">
                    قراءة المزيد
                  </Link>
                </div>
              </div>
            </div>

            <div className="details ar">
              <h3
                id="5"
                className={linkIndek === "5" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>05</small>
                المعايير المتبعة
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "5" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={SideImg1} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>المعايير المتبعة</h5>
                  <h2>
                    <span>05</span>
                    المعايير المتبعة
                  </h2>
                  <p>
                    1- <span>قوة التأثير:</span>
                    ليكون لأقل عدد من المنتجات تأثير جذري بحياة الأشخاص.
                  </p>
                  <p>
                    2- <span>سوبرفود:</span>
                    توفر مستويات عالية التركيز للعديد من العناصر الغذائية
                    الأساسية.
                  </p>
                  <p>
                    3- <span>الوقاية:</span>
                    تعزيز الوقاية من الأمراض.
                  </p>
                  <p>
                    4- <span>فوائد متعددة:</span>
                    فوائد صحية وعلاجية متعددة في وقت واحد.
                  </p>
                  <p>
                    5- <span>قدرات خاصة:</span>
                    تعزيز الشعور بالطاقة وتحسين الأداء البدني والذهني وضبط الوزن
                    والمزاج وتوازن الهرمونات وتعزيز الشعور بالراحة.
                  </p>
                  <p>
                    6- <span>سهولة وتعدد الاستخدامات:</span>
                    يسهل ادراجها بحياتنا اليومية وبذلك يمكننا استبدال الاضرار
                    التراكمية من السموم المتناولة بتكرار عالي الي فوائد تراكمية.
                  </p>
                  <p>
                    7- <span>شامل:</span>
                    لمختلف الأهداف الصحية ويسهل توظيفها طبقا لكل هدف الشخصي.
                  </p>
                  <p>
                    8- <span>الاستخدام الآمن:</span>
                    مناسبة لجميع الاعمار والحالات المختلفة مثل مرضي الضغط والسكر
                    والقولون ومتبعي الحميات النباتية والحميات منخفضة السعرات.
                  </p>
                  <p>
                    9- <span>لذيذة:</span>
                    يجب ان يحبها المستهلك ويتلذذ بها.
                  </p>
                  <Link to="/ar/about" className="read-more">
                    قراءة المزيد
                  </Link>
                </div>
              </div>
            </div>

            <div className="details ar">
              <h3
                id="6"
                className={linkIndek === "6" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>06</small>
                منتجاتنا
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "6" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design6} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>منتجاتنا</h5>
                  <h2>
                    <span>06</span>
                    منتجاتنا
                  </h2>
                  <p>
                    منتجاتنا عبارة مصفوفة طبيعة مصممة خصيصا لعصرنا الحالي، ناتجة
                    عن تركيز أطنان من النباتات الغنية في بضع جرامات مكونة مزيج
                    فريد عالي الكثافة من العناصر الغذائية الحيوية والمركبات
                    النشطة التي تعمل معًا لتوفير مجموعة واسعة من الفوائد الصحية
                    وتعزيز الصحة والعافية بتركيزات اعلي واشمل من كافة المكملات
                    الصيدلانية.
                  </p>
                  <p>
                    والأهم انه بجانب قدراتها الصحية والعلاجية العديدة، فإنها
                    لذيذة وسهلة ومتعددة الاستخدامات كما يمكن توظيفها لخسارة
                    الوزن بشكل صحي او تناولها بكميات أكبر وتردد استخدام اعلي
                    لعاج النحافة أيضا بصورة صحية.
                  </p>
                  <p>
                    نقدمها بصورة نقية 100% دون أي مواد حافظة أو أي إضافات، كما
                    أنها خالية من الجلوتين واللاكتوز والسكريات المضافة وليسيثين
                    الصويا. لذا فهي آمنة تمامًا على الصحة ومناسبة لجميع الأعمار
                    والحميات الغذائية المختلفة.
                  </p>
                  <Link to="/ar/about" className="read-more">
                    قراءة المزيد
                  </Link>
                </div>
              </div>
            </div>

            <div className="details ar">
              <h3
                id="7"
                className={linkIndek === "7" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>07</small>
                الدبس والطحينة
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "7" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design7} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>الدبس والطحينة</h5>
                  <h2>
                    <span>07</span>
                    الدبس والطحينة
                  </h2>
                  <p>
                    في آدم للمواد الغذائية نؤمن بأن الطعام الصحي لا يجب أن يكون
                    مملًا أو عديم النكهة. فنحن لا نصنع مجرد منتجات غذائية، بل
                    نصنع تجارب غذائية أصيلة لا تُنسى. مع آدم، يمكنك الاستمتاع
                    بالطعم والصحة معًا. عندما يجتمع دبس القصب مع الطحينة، ينشأ
                    مزيج لا يقاوم من النكهات والقوام. هذا الثنائي المميز يزيد من
                    تركيز وتنوع المغذيات بكل ملعقة كما ان إضافة الطحينة الي
                    الدبس تقوم على تقليل المؤشر الجلايسيمي للخليط الناتج مما
                    يجعله مناسبا حتى لمتبعي الكيتو دايت.
                  </p>
                  <p>
                    يوفر هذا المزيج من آدم مجموعة شاملة من العناصر الغذائية بما
                    في ذلك البروتين والألياف والدهون الصحية والفيتامينات
                    والمعادن ومضادات الأكسدة. فبمعلقتان من الدبس ومعلقة من
                    الطحينة تغطي أكثر من 80% من الاحتياج اليومي لتلك العناصر
                    الغذائية المهمة، فقط بمحتوي سعرات يتراوح بين 8 - 10% من
                    سعرات الثبات اليومية مما يعني أن هذا المزيج بمثابة صيدلية
                    كاملة ممتلئة بالمكملات الغذائية المركزة والأمنة.
                  </p>
                  <Link to="/ar/about" className="read-more">
                    قراءة المزيد
                  </Link>
                </div>
              </div>
            </div>


            <div className="details ar">
              <h3
                id="8"
                className={linkIndek === "8" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>08</small>
                منهجيتنا الفريدة
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "8" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design8} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>منهجيتنا الفريدة</h5>
                  <h2>
                    <span>08</span>
                    منهجيتنا الفريدة
                  </h2>
                  <p>
                  <span>التركيز:</span>
                   اكسبنا سر صنعة فريد حتي استطعنا الوصول بجودة ومواصفات منتجاتنا الي اعلي الافاق الي ان أصبحت غير قابلة للمقارنة بأي نسخة أخري.
                  </p>
                  <p>
                  <span>الجودة الفائقة:</span>
                   التزام صارم بأعلى معايير الجودة في جميع مراحل الإنتاج.
                  </p>
                  <p>
                  <span>الحفاظ علي القيمة الغذائية:</span>
                   معالجة خاصة للحفاظ على القيمة الغذائية العالية.
                  </p>
                  <p>
                  <span>النقاء:</span>
                   نقدم منتجاتنا بنقاء 100% وخالية تمامًا من السكريات والزيوت المضافة والمواد الحافظة والمواد الكيميائية.
                  </p>
                  <p>
                  <span>المعالجة الفيزيائية فقط:</span>
                   لا نستخدم الحرارة العالية أو أي مواد كيميائية تتعارض مع رسالتنا الصحية.
                  </p>
                  <p>
                  <span>التعبئة والتغليف المدروس:</span>
                   نقدم منتجاتنا في عبوات مدروسة بعناية للحفاظ على نكهتها وجودتها والابتعاد كليا عن العبوات البلاستيكية .
                  </p>
                  <p>
                  <span>الابتكار والتطوير المستمر:</span>
                   لا نتوقف عن الابتكار والتحسين والتطوير لتلبية احتياجات عملائنا.
                  </p>
                  <p>
                  <span>الطبيعة:</span>
                   نستخدم أفضل المحاصيل تامة النضج الغير متلاعب بجيناتها.
                  </p>
                  <Link to="/ar/about" className="read-more">
                    قراءة المزيد
                  </Link>
                </div>
              </div>
            </div>

            <div className="details ar">
              <h3
                id="9"
                className={linkIndek === "9" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>09</small>
                حول العالم
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "9" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design9} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>حول العالم</h5>
                  <h2>
                    <span>09</span>
                    حول العالم
                  </h2>
                  <p>
                  تلعب شركة آدم للمواد الغذائية دور هام في العديد من الأسواق العالمية، حيث تقوم بتصدير منتجاتها منذ عامها الأول إلى العديد من الدول المختلفة ذات الاشتراطات الصحية الأكثر تحديا حتي أصبحت المفضلة لتلك الشعوب مع نسبة احتفاظ بالعملاء سواء مستهلكين أو موزعين تصل لأكثر من 98%.
                  </p>
                  <Link to="/ar/about" className="read-more">
                    قراءة المزيد
                  </Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
};

export default InfoAr;
