import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import Part1 from "../../../images/imgs/basma-logo.png";
import Part2 from "../../../images/imgs/snabel-logo.png";
import Part3 from "../../../images/imgs/melina-logo.png";
import Part4 from "../../../images/imgs/emilia-logo.png";
import Part5 from "../../../images/imgs/omara-logo.png";

import { Autoplay } from "swiper/modules";

const TestimonialsAr = () => {
  const imagesList = [Part1, Part2, Part3, Part4, Part5];
  return (
    <section className="testimonials">
      <div className="container">
        <div className="testimonials-container ar">
          <div className="title">
            <h5>قصص النجاح</h5>
            <h3>شركاء النجاح</h3>
          </div>
          <div className="testimonials-wraper">
            <div className="testimonial">
              <div className="testimonial-details">
                <p>
                تشرفت شركة آدم للمواد الغذائية بالمساهمة في بناء وتعزيز العديد من العلامات التجارية العالمية. ومن خلال التعاون المتبادل، اكتسبت هذه العلامات التجارية استحسان جمهورها، وحققت نجاحات واسعة ومستدامة، وكانت الأسرع نمواً
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="parteners">
        <Swiper
          className="mySwiper"
          slidesPerView={5}
          spaceBetween={20}
          breakpoints={{
            350: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            768: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
            1024: {
              slidesPerView: 5,
              spaceBetween: 50,
            },
          }}
        >
          {imagesList.map((img, index) => (
            <SwiperSlide key={index}>
              <img loading="lazy" src={img} alt="" />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <p className="last-p">
      تنتج الشركات المالكة لتلك العلامات التجارية مجموعة واسعة من المنتجات الغذائية ذات الجودة العالية. ومع ذلك، عندما يتعلق الأمر بالدبس أو الطحينة، فأنه على الرغم من قيمتها العالية، إلا أنها تتطلب خبرة ومهارة خاصة لإنتاجها بطريقة تجمع بين الطعم والقيمة الغذائية والجودة العالية. ولهذا السبب تطلب تميز علامتهم التجارية بمنتج استثنائي وفريد من نوعه تعاوناً مشتركاً لتقوم آدم بما تبرع به بعمليات الإنتاج وهم يقومون بتوزيعه داخل بلادهم تحت علامتهم الخاصة. وبذلك تمكنوا من توفير نسخ فريدة لا يوفرها سوي آدم من خلالهم حتي استطاعوا اكتساب ولاء وثقة الشريحة المهتمة بالجودة العالية وأصبحت منتجاتنا بمثابة  رأس الحربة بمحفظة منتجاتهم علي طول الخط
      </p>
    </section>
  );
};

export default TestimonialsAr;
