import React from 'react'
import Logo from '../images/imgs/logo-light.png'
import Phone from '../images/svg/phone.svg'
import Mail from '../images/svg/mail.svg'
import Location from '../images/svg/location.svg'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <footer className='footer'>
        <div className='container'>
            <div className='footer-container'>
                <div className='info-footer'>
                    <div className='logo-footer'>
                        <img src={Logo} alt='logo-footer'/>
                    </div>
                    <div className='info-footer-wraper'>
                        <p>
                        Our mission is to educate current and future generations on the basics of health and wellness.
                        </p>
                    </div>
                    <div className='copyright'>
                        <p>© 2024 ADAM, Developed by <a href='https://www.facebook.com/omar.zezo.5268/' target='_blank'>Omar Abd El-aziz</a></p>
                    </div>
                </div>
                <div className='links-footer'>
                    <h6>Links</h6>
                    <div className='links-footer-wraper'>
                        <ul>
                            <li>
                            <Link to="/">Home</Link>
                            </li>
                            <li>
                            <Link to="/about">About Us</Link>
                            </li>
                            <li>
                            <Link to="/blog">Blog</Link>
                            </li>
                            <li>
                            <Link to="/contact">Contact</Link>
                            </li>
                            <li>
                            <Link to="/shop">Shop</Link>
                            </li>
                        </ul>

                        <ul>
                            <li>
                            <Link to="">Molasses</Link>
                            </li>
                            <li>
                            <Link to="">Tahini</Link>
                            </li>
                            <li>
                            <Link to="">Linkedin</Link>
                            </li>
                            <li>
                            <Link to="">Facebook</Link>
                            </li>
                            <li>
                            <Link to="">Instagram</Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className='contacts-footer'>
                    <h6>Contacts</h6>
                    <ul className='contacts-footer-wraper'>
                        <li>
                            <Link to="">
                                <img src={Phone} alt=''/>
                                +201001103654
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <img src={Mail} alt=''/>
                                info@adamfoodseg.com
                            </Link>
                        </li>
                        <li>
                            <Link to="">
                                <img src={Location} alt=''/>
                                Egypt
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </footer>
  )
}

export default Footer