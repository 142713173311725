import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import BG1 from "../../../images/imgs/bg-body4.jpg";
import Adam3p from "../../../images/imgs/3p-adam.webp";
import Design3 from "../../../images/imgs/design-3.webp";
import Design2 from "../../../images/imgs/design-2.webp";

import { Pagination } from "swiper/modules";

const MainSliderAr = () => {
  return (
    <section className="intro">
      <div className="social-media-links">
        <div className="social">
          <a href="" target="blanck">
            <span>IN</span>
            <p>INSTAGRAM</p>
          </a>
        </div>
        <div className="social">
          <a href="" target="blanck">
            <span>TW</span>
            <p>TWITTER</p>
          </a>
        </div>
        <div className="social">
          <a href="" target="blanck">
            <span>FB</span>
            <p>FACEBOOK</p>
          </a>
        </div>
      </div>

      <Swiper
        pagination={{
          clickable: true,
        }}
        modules={[Pagination]}
        className="mySwiper ar"
      >
        <SwiperSlide>
          <div className="container ar">
            <div className="slide-img">
              <img loading="lazy" src={Adam3p} alt="slide-img" />
            </div>

            <div className="info-wraper ar">
              <h3>أدم للمواد الغذائية</h3>
              <p>
                تخطى المألوف 30 عاماً
                <br />
                <span>بين يديك</span>
                من الإبداع
              </p>
              <div className="work-with">
                <Link to="">اكتشف الأن</Link>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="container ar">
            <div className="slide-img">
              <img loading="lazy" src={Design3} alt="slide-img" />
            </div>

            <div className="info-wraper ar">
              <h3>أدم للمواد الغذائية</h3>
              <p>
                مٌنتجات أدم خيارك الأمثل
                <br />
                <span>الرفاهية</span>
                من أجل
              </p>
              <div className="work-with">
                <Link to="">اكتشف الأن</Link>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="container ar">
            <div className="slide-img">
              <img loading="lazy" src={Adam3p} alt="slide-img" />
            </div>

            <div className="info-wraper ar">
              <h3>أدم للمواد الغذائية</h3>
              <p>
                قم ببناء نفسك لتصبح
                <br />
                <span>اليوم</span>
                أفضل نسخة منك
              </p>
              <div className="work-with">
                <Link to="">اكتشف الأن</Link>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default MainSliderAr;
