import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import Product1 from '../../../images/imgs/product-1.webp'
import Product2 from '../../../images/imgs/product-2.webp'

// import required modules
import { Navigation } from "swiper/modules";

const OurProductsAr = () => {
  return (
    <section id="products" className="our-products">
      <div className="container">
        <div className="our-products-wraper ar">
          <h5>منتجاتنا</h5>
          <h2 className="title">أحدث المُنتجات</h2>
          <div className="products-container">
            <Swiper
              navigation={true}
              modules={[Navigation]}
              className="mySwiper"
            >
              <SwiperSlide>
                <div className="slide-number">
                    <p>01</p>
                    <p>02</p>
                </div>
                <div className="product-img">
                    <img loading="lazy" src={Product1} alt="product-1"/>
                </div>
                <div className="product-info">
                    <h3>
                    دبس القصب من آدم<br/>نقي %100<br/>دون أي إضافات
                    </h3>
                    <small>منتجات أدم</small>
                </div>
              </SwiperSlide>

              <SwiperSlide>
                <div className="slide-number">
                    <p>02</p>
                    <p>02</p>
                </div>
                <div className="product-img">
                    <img loading="lazy" src={Product2} alt="product-1"/>
                </div>
                <div className="product-info">
                    <h3>
                    طحينة السمسم من آدم<br/>نقية %100<br/>دون أي إضافات
                    </h3>
                    <small>منتجات أدم</small>
                </div>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProductsAr