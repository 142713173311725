import React, { useState } from "react";
import { Link } from "react-router-dom";
import SideImg1 from "../../images/imgs/design-4.webp";
import Design5 from "../../images/imgs/design-5.webp";
import Design6 from "../../images/imgs/design-6.webp";
import Design7 from "../../images/imgs/design-7.webp";
import Design8 from "../../images/imgs/design-8.webp";
import Design9 from "../../images/imgs/design-9.webp";

const Info = () => {
  const [linkIndek, setLinkIndex] = useState("1");
  return (
    <section className="info">
      <div className="overlay"></div>
      <div className="container">
        <div className="info-wraper">
          <div className="details-container">
            <div className="details">
              <h3
                id="1"
                className={linkIndek === "1" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>01</small>
                About Us
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "1" ? "flex" : "none" }}
              >
                <div className="img">
                  <img src={SideImg1} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>ABOUT US</h5>
                  <h2>
                    <span>01</span>
                    Our Philosophy
                  </h2>
                  <p>
                  We believe that health, strength, and fitness are the most important pillars of happiness in our lives and a major goal for many. We also believe that the way to achieve them is not through harsh diets that only work for a few for a limited time before their health deteriorates and refraining from the pleasure of eating what we love. 
                  </p>
                  <Link to="/about" className="read-more">READ MORE</Link>
                </div>
              </div>
            </div>

            <div className="details">
              <h3
                id="2"
                className={linkIndek === "2" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>02</small>
                Vision
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "2" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design5} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>Vision</h5>
                  <h2>
                    <span>02</span>
                    Our Vision
                  </h2>
                  <p>
                  A world where everyone enjoys the benefits of modern civilization and the health of our ancestors. We're on a quest to elevate our species to the best version of ourselves!
                  </p>
                  <Link to="/about" className="read-more">READ MORE</Link>
                </div>
              </div>
            </div>

            <div className="details">
              <h3
                id="3"
                className={linkIndek === "3" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>03</small>
                Our Mission
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "3" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design5} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>Mission</h5>
                  <h2>
                    <span>03</span>
                    Our Mission
                  </h2>
                  <p>
                  Our mission is to educate current and future generations on the basics of health and wellness, as well as the causes of disease. While providing effective solutions that are easy to use and help people achieve their goals and live happier, healthier lives.
                  </p>
                  <Link to="/about" className="read-more">READ MORE</Link>
                </div>
              </div>
            </div>

            <div className="details">
              <h3
                id="4"
                className={linkIndek === "4" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>04</small>
                Our origins
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "4" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={SideImg1} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>Our origins</h5>
                  <h2>
                    <span>04</span>
                    Our origins
                  </h2>
                  <p>
                  In 1995, Adam Foodstuffs was established with a mission to make health and wellness accessible to everyone, while simplifying the transition to a healthy lifestyle.
                  </p>
                  <p>
                  We believe that any successful change in a person's life begins with replacing some repetitive negative habits with better ones. This is a fundamental principle that the company keeps in mind when it comes to correcting and changing community members' lives for the better, without disrupting their lifestyle.
                  </p>
                  <p>
                  That's why Adam has set some criteria for selecting the products it offers to ensure that they contribute to improving overall health.
                  </p>
                  <Link to="/about" className="read-more">READ MORE</Link>
                </div>
              </div>
            </div>

            <div className="details">
              <h3
                id="5"
                className={linkIndek === "5" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>05</small>
                Selection criteria
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "5" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={SideImg1} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>Selection criteria</h5>
                  <h2>
                    <span>05</span>
                    Selection criteria
                  </h2>
                  <p>
                  1- <span>Powerful impact:</span> A few products can have a big impact on people's lives.
                  </p>
                  <p>
                  2- <span>Superfood:</span> Provides highly concentrated levels of many essential nutrients.
                  </p>
                  <p>
                  3- <span>Prevention:</span> Promote disease prevention.
                  </p>
                  <p>
                  4- <span>Multiple Benefits:</span> Multiple health and therapeutic benefits simultaneously.
                  </p>
                  <p>
                  5- <span>Special abilities:</span> Improve energy, physical and mental performance, enhancing mood, manage weight, improve hormones balance.
                  </p>
                  <p>
                  6- <span>Easy and versatile:</span> Easy to use to and incorporate into our daily lives and can replace the cumulative damage from frequently ingested toxins with cumulative benefits.
                  </p>
                  <p>
                  7- <span>Comprehensive:</span> For different health goals.
                  </p>
                  <p>
                  8- <span>Safe to use:</span> Suitable for all ages and different conditions.
                  </p>
                  <p>
                  9- <span>Delicious:</span> The consumer must love it.
                  </p>
                  <Link to="/about" className="read-more">READ MORE</Link>
                </div>
              </div>
            </div>

            <div className="details">
              <h3
                id="6"
                className={linkIndek === "6" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>06</small>
                Amazing products
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "6" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design6} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>Amazing products</h5>
                  <h2>
                    <span>06</span>
                    Amazing products
                  </h2>
                  <p>
                  Our products are a specially designed natural matrix for today's age. They're the result of concentrating tons of rich botanicals into a few grams, forming a unique high-density blend of vital nutrients and active compounds that work together to provide health benefits. They are more comprehensive than all pharmaceutical supplements. And there’s more! Not only are they packed with health and therapeutic benefits, but they’re also delicious, easy to use, and versatile. You can even use them for healthy weight loss or eat them in larger quantities to treat thinness also in a healthy way. They’re 100% pure with no preservatives or additives, and they’re free of gluten, lactose, added sugars, and soy lecithin. So they’re completely safe for your health and suitable for all ages and diets.
                  </p>
                  <Link to="/about" className="read-more">READ MORE</Link>
                </div>
              </div>
            </div>


            <div className="details">
              <h3
                id="7"
                className={linkIndek === "7" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>07</small>
                Molasses and Tahini 
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "7" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design7} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>Molasses and Tahini</h5>
                  <h2>
                    <span>07</span>
                    Molasses and Tahini 
                  </h2>
                  <p>
                  At Adam Foodstuffs, we believe that healthy food doesn't have to be boring or flavorless. We don't just make food products, we make authentic and memorable food experiences! With Adam, you can enjoy both taste and health.
                  </p>
                  <p>
                  When cane molasses meets tahini, an irresistible combination of flavors and textures is created! This incredible duo packs a powerful punch, delivering an unbeatable concentration of nutrients with very low glycemic index of the resulting mixture, making it suitable even for keto dieters. 
                  </p>
                  <p>
                  This unique combination provides a wide range of nutrients, including protein, fiber, healthy fats, vitamins, minerals, and antioxidants. Two tablespoons of molasses and one tablespoon of tahini provide more than 80% of the daily requirement for these important nutrients, with only 8-10% of the daily calorie content. This blend is a complete pharmacy full of concentrated and safe supplements.
                  </p>
                  <Link to="/about" className="read-more">READ MORE</Link>
                </div>
              </div>
            </div>


            <div className="details">
              <h3
                id="8"
                className={linkIndek === "8" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>08</small>
                Unique methodology
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "8" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design8} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>Unique methodology</h5>
                  <h2>
                    <span>08</span>
                    Unique methodology 
                  </h2>
                  <p>
                  <span>Focus Strategy:</span> we have acquired a unique manufacturing secret and have been able to take the quality and specifications of our products to the next level, making them incomparable to any other version.
                  </p>
                  <p>
                  <span>Superior quality:</span> Strict adherence to the highest quality standards at all stages of production 
                  </p>
                  <p>
                  <span>Preserve nutritional value:</span> Special processing to maintain high nutritional value.
                  </p>
                  <p>
                  <span>Just physical Processing:</span> We don't use high heat or any chemicals that interfere with our health message.
                  </p>
                  <p>
                  <span>Purity:</span> 100% pure and completely free of added sugars, added oils, preservatives and chemicals.
                  </p>
                  <p>
                  <span>Continuous innovation:</span> to meet our customers' needs.
                  </p>
                  <p>
                  <span>Thoughtful processing & packaging:</span> We offer our products in carefully designed packaging that preserves their taste and quality, and stay away from plastic packaging that is questionable for long-term health and repeated use.
                  </p>
                  <p>
                  <span>Nature:</span> only the best, non-GMO crops that are treated with care to preserve their nutritional value.
                  </p>
                  <Link to="/about" className="read-more">READ MORE</Link>
                </div>
              </div>
            </div>


            <div className="details">
              <h3
                id="9"
                className={linkIndek === "9" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>09</small>
                Around the world
              </h3>
              <div
                className="inner-details"
                style={{ display: linkIndek === "9" ? "flex" : "none" }}
              >
                <div className="img">
                  <img loading="lazy" src={Design9} alt="side-img" />
                </div>
                <div className="inner-info">
                  <h5>Around the world</h5>
                  <h2>
                    <span>09</span>
                    Around the world 
                  </h2>
                  <p>
                  Adam Foodstuffs is a global success story! Since its first year, Adam has been exporting its products to many different countries with the most challenging health requirements until became the favorite of those peoples with a customer retention rate, whether consumers or distributors, that reaches more than 98%.
                  </p>
                  <Link to="/about" className="read-more">READ MORE</Link>
                </div>
              </div>
            </div>


          </div>
          <div className="links">
            <ul>
              <li
                id="1"
                className={linkIndek === "1" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>01</small>
                About Us
              </li>
              <li
                id="2"
                className={linkIndek === "2" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>02</small>
                Vision
              </li>
              <li
                id="3"
                className={linkIndek === "3" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>03</small>
                Mission
              </li>
              <li
                id="4"
                className={linkIndek === "4" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>04</small>
                Origins
              </li>
              <li
                id="5"
                className={linkIndek === "5" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>05</small>
                Selection criteria
              </li>
              <li
                id="6"
                className={linkIndek === "6" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>06</small>
                Amazing products
              </li>
              <li
                id="7"
                className={linkIndek === "7" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>07</small>
                Molasses and Tahini
              </li>
              <li
                id="8"
                className={linkIndek === "8" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>08</small>
                unique methodology 
              </li>
              <li
                id="9"
                className={linkIndek === "9" ? "active" : ""}
                onClick={(e) => setLinkIndex(e.target.id)}
              >
                <small>09</small>
                Around the world
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Info;
