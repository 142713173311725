import {GET_ALL_USERS, GET_SPECIFIC_USER} from '../types'

const initState = {
    allUsers: [],
    specificUser: []
}

const usersReducer = (state = initState, action )=>{
    switch(action.type){
        case GET_ALL_USERS:
            return {allUsers: action.payload}
        case GET_SPECIFIC_USER:
            return {specificUser: action.payload}
        default:
            return state    
    }
}

export default usersReducer